import { Link } from "react-router-dom";
function Projects() {
    return (
        <>
            <div class="flex pt-4 pb-4 flex-col  ">

                <div class=" font-bold">
                    Projects

                </div>

                <div class="flex flex-grow flex-col pt-8 font-normal sm:max-w-[45%]">
                    {/* border border-yellow-500 */}
                    Projects I've worked on. 
                    {/* <span class = "text-gray-700 pt-2"><Link class = "hover:underline" to = "">David Dobrik</Link></span> */}
                    
                    
                    <span class="text-gray-700 pt-8">1. <Link target="_blank" class="hover:underline font-medium" to="https://adityasoni2019.notion.site/Spotify-Project-Doc-9358d37b44434828b3e4501f3b25686f?pvs=4">Spotify Account Sync (Solves a real personal problem)</Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/spotify">GitHub</Link></span>
                    <span class="text-gray-700 pt-2">2. <Link target="_blank" class="hover:underline font-medium" to="https://privatexnotes.netlify.app/">Custom Notes App</Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/teleport_/">GitHub</Link></span>
                    <span class="text-gray-700 pt-2">3. <Link target="_blank" class="hover:underline font-medium" to="https://gpay-expense-tracker.netlify.app/">GPay Expense Tracker</Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/gpay-expense-tracker">GitHub</Link></span>
                    <span class="text-gray-700 pt-2">4. <Link target="_blank" class="hover:underline font-medium" to="https://google-map-cuboid.netlify.app/">3D stuff</Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/snaptrude">GitHub</Link></span>
                    <span class="text-gray-700 pt-2">5. <Link target="_blank" class="hover:underline font-medium" to="https://chip-component-custom.netlify.app/">Google Doc Component (with backspace function)</Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/chip-component">GitHub</Link></span>
                    <span class="text-gray-700 pt-2">6. <Link target="_blank" class="hover:underline font-medium" to="https://youtube-clone-aditya.netlify.app/">YouTube Clone </Link> - <Link target="_blank" class="hover:underline" to="https://github.com/adityasoni2019/YouTube-Clone">GitHub</Link> </span>
                    <span class="text-gray-700 pt-2">7. <Link target="_blank" class="hover:underline font-medium" to="https://adityasoni9727.medium.com/coworking-space-management-system-eaad75213cf1">Coworking Space Management System (Design Project)</Link></span>
                    <br/>
                    <span class = "italic font-thin">*Click on titles for deployed links</span>
                </div>
            </div>
        </>
    );

}

export default Projects;

