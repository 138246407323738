import { Link } from "react-router-dom";

function Songs() {

    return (
        <>

            <div class="flex pt-4 flex-grow pb-4 flex-col ">
                {/* border border-pink-500 */}

                <div class="font-bold">
                    Bands or Songs that I like
                </div>

                <div class="flex flex-grow flex-col pt-8 font-normal sm:max-w-[45%]">
                    {/* border border-yellow-500 */}
                    {/* <span class = "text-gray-700 pt-2"><L
                    ZZk class = "hover:underline" to = "">David Dobrik</Link></span> */}
                    Bands
                    <span class="text-gray-700 pt-8"><Link class="hover:underline" to="https://nav.al/">Mazzy Star</Link></span>
                    <span class="text-gray-700 pt-2"><Link class="hover:underline" to="https://nav.al/">Naval</Link></span>
                    <span class="text-gray-700 pt-2"><Link class="hover:underline" to="https://nav.al/">Naval</Link></span>
                    <span class="text-gray-700 pt-2"><Link class="hover:underline" to="https://nav.al/">Naval</Link></span>
                    <br/>
                    <br/>
                    <br/>
                    Songs 
                    <span class="text-gray-700 pt-8"><Link class="hover:underline" to="https://nav.al/">Naval</Link></span>

                </div>
            </div>

        </>
    )
}

export default Songs;