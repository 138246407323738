function Books() {
  return (
    <>
      <div class="flex pt-4 pb-4 flex-col   ">

        <div class=" font-bold">
          Book Recos - This is under construction
        </div>
        <div class="flex flex-col pt-8 font-normal">
          {/* <span class="text-gray-700 pt-8"><Link class="" to="">The Prophet</Link></span> */}
        </div>
      </div>
    </>
  );
}

export default Books;