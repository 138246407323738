

function Blogs() {
  return (
    <>
        <div class = "flex pt-4 pb-4 flex-col ">

            <div class=" font-bold">
                Blogs

            </div>
            <div class = "flex flex-col pt-8 font-normal">
            </div>
        </div>
    </>
);
}

export default Blogs; 