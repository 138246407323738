// this is the home page. 
function Home() {


    function composeEmail() {
        var email = 'adityasoni9727@gmail.com';
        var subject = 'Hey Aditya!';
        // Construct the mailto URL
        var mailtoLink = 'mailto:' + email + '?subject=' + encodeURIComponent(subject);

        // Open the user's default email client
        window.location.href = mailtoLink;
    }

    return (
        <>
            <div class="flex pt-4 pb-4 flex-col  ">

                <div class=" font-bold">
                    Home

                </div>
                <div class="flex flex-col pt-8 font-normal">
                    Hey this is Aditya.
                    <br />
                    Love indie stuff.
                    <br />
                    You can best reach out to me at <span class="hover:cursor-pointer underline"> <span onClick={composeEmail}>Gmail</span></span>
                    <br />
                    <br />

                </div>
            </div>
        </>
    );
}

export default Home;
