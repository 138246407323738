import { Link } from "react-router-dom";

function Internet() {
    return (
        <>
            <div class="flex pt-4 flex-grow pb-4 flex-col ">
            {/* border border-pink-500 */}
        
                <div class="font-bold">
                    Internet
                </div>

                <div class="flex flex-grow flex-col pt-8 font-normal sm:max-w-[45%]">
                {/* border border-yellow-500 */}
                    These are some things that I find interesting on the internet.
                    {/* <span class = "text-gray-700 pt-2"><Link class = "hover:underline" to = "">David Dobrik</Link></span> */}
                    <span class = "text-gray-700 pt-8"><Link class = "hover:underline" target="_blank" to = "https://nav.al/">Naval</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@casey">Casey Neistat</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@vanneistat">Van Neistat</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.netflix.com/in/title/80049714">Aziz Ansari</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://paulgraham.com/articles.html">Paul Graham</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://twitter.com/dharmesh">Dharmesh</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@MyFirstMillionPod">Shaan Puri</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@MyFirstMillionPod">Sam Parr</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://twitter.com/awilkinson">Andrew Wilkinson</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://en.wikipedia.org/wiki/Mimetic_theory">Mimetic Theory</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://waitbutwhy.com/">Wait But Why</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://everynoise.com/">Every Noise</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@Sisyphus55">Sisyphus 55</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@HorsesOnYT">HorsesOnYT</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@Eternalised">Eternalised</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/watch?v=vaIH5tLmC8U">Makes you wonder</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://godly.website/">Godly Website</Link></span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@Exurb1a">Exurb1a</Link> </span>
                    <span class = "text-gray-700 pt-2"><Link class = "hover:underline" target="_blank" to = "https://www.youtube.com/@exurb2a114">Exurb2a</Link> </span>
                    
                </div>
            </div>
        </>
    );
}

export default Internet; 