
function About() {
    return (
        <>
            <div class="flex pt-4 flex-grow pb-4 flex-col">
            {/* border border-pink-500 */}

                <div class=" font-bold">
                    About
                </div>

                <div class="flex flex-grow flex-col pt-8 font-normal max-w-[45%]">
                {/* border border-yellow-500  */}
                Some quotes that I relate to.
                    <div class="pl-8 pt-8 flex flex-col">
                        <span>“I have to understand the world, you see.”</span>
                        <span class="font-style: italic pt-2">- Richard Feynman</span>
                    </div>
                  
                </div>
            </div>
        </>
    );
}

export default About; 