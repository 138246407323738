import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function ErrorPage() {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 3500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="text-center text-lg">
                    When you think you're lost, not knowing what to do, which direction to go, suddenly the right path appears in front of you
                </div>

                <div className={`mt-4 transition-opacity duration-500 ${showButton ? 'opacity-100' : 'opacity-0'}`}>
                    <Link to="/" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full inline-block">
                        Go to Home
                    </Link>
                </div>

            </div >
        </>
    );
}

export default ErrorPage; 