import { Link } from "react-router-dom";

// this is the home page. 
function Podcast() {
    return (
        <>
            <div class = "flex pt-4 pb-4 flex-col   ">

                <div class=" font-bold">
                    Podcast
                </div>

                <div class = "flex flex-col pt-8 font-normal">
                    <Link class = "pt-2 text-gray-700 hover:underline" to="https://open.spotify.com/show/51uR3uUskUU8c4hthob2hB">Spotify</Link>
                    <Link class = "pt-2 text-gray-700 hover:underline" to="https://podcasts.apple.com/us/podcast/the-voice-precis/id1551045554">Apple Podcast</Link>
                </div>
            </div>
        </>
    );
}

export default Podcast;