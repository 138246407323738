import { useState } from "react";
import close from "../Assets/close_icon.svg"
import { Link } from "react-router-dom";

function Popup() {

    const [hidePopup, setHidePopup] = useState(false);

    function onCloseClick() {
        setHidePopup(true);
    }

    return (
        <>
            <div hidden={hidePopup} class="p-8 h-screen text-white w-screen bg-black font-light z-999"  >
                <div class=" fixed h-scren w-screen">
                    <div onClick={onCloseClick} class="bg-black mt-12" >
                        <button >
                            <img alt="Close Icon" widht="24px" height="24px" src={close} />
                        </button>
                    </div>

                    <div class="flex flex-col pt-8" >
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="projects">Personal Projects</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="blogs">Blogs</Link></span> */}
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="people">People</Link></span> */}
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="internet">Internet</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="products">Products</Link></span> */}
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="podcast">My Podcast</Link></span>
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="https://panopticnotes.substack.com/" target="_blank">Panoptic Notes</Link></span>
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="https://www.figma.com/design/yznZEzI4cX8mPuh0rHiUyu/Aditya's-Gallery?node-id=0-1&t=loeUQbEOVtD5J3NH-1" target="_blank">Figma</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="https://drive.google.com/file/d/14yFbyqrGd25IJeEKO18SV9aXU_PknnfH/view?usp=sharing" target="_blank">Resume</Link></span> */}
                        <span onClick={onCloseClick} class="pt-2 font-normal"><Link class="hover:underline" to="books">Books</Link></span>

                    </div>
                </div>
            </div >
        </>
    )
}

export default Popup; 