import { Link } from "react-router-dom";
import menu from "../Assets/menu_og.svg"
import { useState } from "react";
import Popup from "./Popup";
import Footer from "./Footer";


function LeftPanel() {

    const [showPopup, setShowPopup] = useState(false);
    function onClickMenu() {
        setShowPopup(!showPopup);
    }

    return (
        <>
            <div class="pl-4 pt-4 pb-4 pr-4 flex flex-col justify-between min-w-[25%]">

                <div class="">

                    <div class="font-normal sm:text-blue-500 ">
                        <Link class=" hover:underline hover:text-red-500" to="/">
                            Aditya Soni
                        </Link>
                    </div>

                    <div onClick={onClickMenu} class="bg-white sm:hidden">
                        <button class="mt-8 ">
                            <img alt="menu_icon" src={menu} width='26' height='26' />
                        </button>
                    </div>

                    {showPopup && (<div class="fixed top-0 right-0 bottom-0 left-0z-50">
                        <Popup />
                    </div>)}

                    <div class="hidden sm:show sm:flex flex-col pt-6 " >
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="/">Home</Link></span> */}
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="about">About</Link></span> */}
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="projects">Personal Projects</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="blogs">Blogs</Link></span> */}
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="people">People</Link></span> */}
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="internet">Internet</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="products">Products</Link></span> */}
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="podcast">My Podcast</Link></span>
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="https://panopticnotes.substack.com/" target="_blank">Panoptic Notes</Link></span>
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="https://www.figma.com/design/yznZEzI4cX8mPuh0rHiUyu/Aditya's-Gallery?node-id=0-1&t=loeUQbEOVtD5J3NH-1" target="_blank">Figma</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="https://drive.google.com/file/d/14yFbyqrGd25IJeEKO18SV9aXU_PknnfH/view?usp=sharing" target="_blank">Resume</Link></span> */}
                        <span class="pt-2 font-normal"><Link class="hover:underline" to="books">Books</Link></span>
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="songs">Fav Bands</Link></span> */}
                        {/* <span class="pt-2 font-normal"><Link class="hover:underline" to="404">Error Page</Link></span> */}
                    </div>
                </div>

                {/* now, when it's medium, I want a footer here. 
                    but, I don't want it when it's small or lower, 
                    cause it's already there from the index.js page.
                */}
                <div class = "hidden md:block">
                    <Footer/>
                </div>
                {/* opposite of hidden in tailwind, is block */}

            </div>
        </>
    )

}

export default LeftPanel;