import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Outlet } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from './Pages/About';
import Projects from './Pages/Projects';

import Blogs from './Pages/Blogs';
import Internet from './Pages/Internet';
import Home from './Pages/Home';
import ErrorPage from './Pages/Error';
import LeftPanel from './Components/LeftPanel';
import Books from './Pages/Books';
import Footer from './Components/Footer';
import Podcast from './Pages/Podcast';
import Songs from './Pages/Songs';

const App = () => {
  return (
    <>
    {/* this format is for small, and lower */}

      <div class="flex flex-col md:flex-row p-4 h-screen font-light text-sm justify-between ">
        <LeftPanel />

        <div class="flex-grow pl-4">
          <Outlet />
        </div>

        <div class=" md:hidden">
          {/* I don't want this here when it's medium or more. */}
          <Footer />
        </div>

      </div>
    </>
  );
}

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/internet",
        element: <Internet />,
      },
      {
        path: "/podcast",
        element: <Podcast />
      },
      {
        path: "/books",
        element: <Books />
      },
      {
        path: "/songs",
        element: <Songs />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorPage />,
  }

]);


ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={appRouter} />
);